<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <item-edit-view
      title="Уведомление"
      :loading="loading"
    >
      <template v-slot:right>
        <v-btn
          right
          color="success"
          :loading="saving"
          @click.prevent="handleSubmit(save)"
        >
          <v-icon small>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </template>

      <v-card>
        <v-form v-if="!loading && item">
          <v-card flat>
            <v-card-text>
              <v-checkbox
                v-model="item.active"
                label="Активирован"
              ></v-checkbox>

              <validation-provider
                v-slot="{ errors }"
                name="Место"
                rules="required"
              >
                <v-select
                  v-model="item.location"
                  :items="locations"
                  chips
                  label="Место"
                  :error-messages="errors"
                  required
                ></v-select>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Тип"
                rules="required"
              >
                <v-select
                  v-model="item.type"
                  :items="types"
                  chips
                  label="Тип"
                  :error-messages="errors"
                  required
                  key="type"
                ></v-select>
              </validation-provider>

              <!-- Frontend not supports yet -->
              <v-row class="mx-0">
                <v-checkbox
                  v-model="item.showOnce"
                  disabled
                  label="Показать только 1 раз"
                  class="mr-4"
                ></v-checkbox>
                <v-checkbox
                  v-model="item.closeable"
                  disabled
                  label="Возможность закрыть"
                ></v-checkbox>
              </v-row>

              <validation-provider
                v-slot="{ errors }"
                name="Сообщение"
                rules="required"
              >
                <v-input
                  :error-messages="errors"
                  required
                  :value="item.message"
                >
                  <quill
                    v-model="item.message"
                    :options="quill"
                    label="Сообщение"
                  >
                  </quill>
                </v-input>
              </validation-provider>

            </v-card-text>
          </v-card>
        </v-form>
      </v-card>
    </item-edit-view>
  </ValidationObserver>
</template>

<script>
import api from '../../services/informer'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import ItemEditView from '../../components/ItemEditView'
import transform from 'lodash/transform'
import { Types, Locations } from './constants'
import { QUILL_SIMPLE } from '../../components/elements/quilljs/configs'

export default {
  components: {
    ItemEditView
  },
  mixins: [createOrUpdateViewMixin(api)],
  computed: {
    types () {
      return transform(Types, (result, value, key) => {
        result.push({ value: key, text: value })
      }, [])
    },
    locations () {
      return transform(Locations, (result, value, key) => {
        result.push({ value: key, text: value })
      }, [])
    },
    quill () {
      return QUILL_SIMPLE
    }
  }
}
</script>
