import {
  LOCATION_ACCOUNT_CARD_PAGE,
  LOCATION_ACCOUNT_PAGE,
  LOCATION_ANY_PAGE,
  LOCATION_MAIN_PAGE,

  TYPE_HINT,
  TYPE_IMPORTANT,
  TYPE_INFO
} from '../../services/informer'

export const Locations = {
  [LOCATION_MAIN_PAGE]: 'Главная страница',
  [LOCATION_ACCOUNT_PAGE]: 'Личный кабинет',
  [LOCATION_ACCOUNT_CARD_PAGE]: 'Лицевой счет',
  [LOCATION_ANY_PAGE]: 'Любая страница'
}

export const Types = {
  [TYPE_IMPORTANT]: 'Важно',
  [TYPE_INFO]: 'Инфо',
  [TYPE_HINT]: 'Подсказка'
}
