var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('item-edit-view',{attrs:{"title":"Уведомление","loading":_vm.loading},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"right":"","color":"success","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Сохранить ")],1)]},proxy:true}],null,true)},[_c('v-card',[(!_vm.loading && _vm.item)?_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-checkbox',{attrs:{"label":"Активирован"},model:{value:(_vm.item.active),callback:function ($$v) {_vm.$set(_vm.item, "active", $$v)},expression:"item.active"}}),_c('validation-provider',{attrs:{"name":"Место","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.locations,"chips":"","label":"Место","error-messages":errors,"required":""},model:{value:(_vm.item.location),callback:function ($$v) {_vm.$set(_vm.item, "location", $$v)},expression:"item.location"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Тип","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{key:"type",attrs:{"items":_vm.types,"chips":"","label":"Тип","error-messages":errors,"required":""},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})]}}],null,true)}),_c('v-row',{staticClass:"mx-0"},[_c('v-checkbox',{staticClass:"mr-4",attrs:{"disabled":"","label":"Показать только 1 раз"},model:{value:(_vm.item.showOnce),callback:function ($$v) {_vm.$set(_vm.item, "showOnce", $$v)},expression:"item.showOnce"}}),_c('v-checkbox',{attrs:{"disabled":"","label":"Возможность закрыть"},model:{value:(_vm.item.closeable),callback:function ($$v) {_vm.$set(_vm.item, "closeable", $$v)},expression:"item.closeable"}})],1),_c('validation-provider',{attrs:{"name":"Сообщение","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{attrs:{"error-messages":errors,"required":"","value":_vm.item.message}},[_c('quill',{attrs:{"options":_vm.quill,"label":"Сообщение"},model:{value:(_vm.item.message),callback:function ($$v) {_vm.$set(_vm.item, "message", $$v)},expression:"item.message"}})],1)]}}],null,true)})],1)],1)],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }