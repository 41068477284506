import BaseService from './baseService'

export const TYPE_IMPORTANT = 'important'
export const TYPE_INFO = 'info'
export const TYPE_HINT = 'hint'

export const LOCATION_MAIN_PAGE = 'main_page'
export const LOCATION_ACCOUNT_PAGE = 'account_page'
export const LOCATION_ACCOUNT_CARD_PAGE = 'account_card_page'
export const LOCATION_ANY_PAGE = 'any_page'

class InformerService extends BaseService {
  get url () {
    return 'informers'
  }

  createModel () {
    return {
      message: null,
      type: null,
      location: null,
      showOnce: false,
      active: false,
      closeable: false
    }
  }
}

export default new InformerService()

